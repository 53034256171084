import { PButton, PButtonPure } from '@porsche-design-system/components-react';
import { Column } from '@tanstack/react-table';
import { useState } from 'react';
import {
  ActionGroup,
  MultiOrganizationSelect,
  MultiOrganizationSelectProps,
  Popover,
} from '../..';

export const OrganizationFilter = <
  T,
  TOrganization extends { id: number; name: string },
>({
  column,
  i18n,
  organizationSelect,
}: {
  column: Column<T>;
  i18n: {
    filterLabel: string;
    optionAll: string;
    buttonReset: string;
    buttonFilter: string;
  };
  organizationSelect: Omit<
    MultiOrganizationSelectProps<TOrganization>,
    'value' | 'onChange'
  >;
}): JSX.Element => {
  const filterValue = column.getFilterValue() as number[] | undefined;
  const [selection, setSelection] = useState(filterValue);
  const [isVisible, setVisibility] = useState(false);

  return (
    <Popover
      target={
        <PButtonPure
          type="button"
          active={(filterValue?.length ?? 0) > 0}
          aria-label={i18n.filterLabel}
          size="x-small"
          icon="filter"
          onClick={() => setVisibility((isVisible) => !isVisible)}
          role="button"
          hideLabel
        />
      }
      open={isVisible}
      onChange={setVisibility}
      placement="right-start"
      strategy="fixed"
      minWidth={800}
      maxWidth={800}
    >
      <MultiOrganizationSelect
        {...organizationSelect}
        value={selection ?? []}
        onChange={(value) => setSelection(value)}
        style={{ minWidth: '100%' }}
        variant="flat"
      />
      <ActionGroup spacing="medium">
        <PButton
          role="button"
          type="button"
          variant="secondary"
          icon="close"
          onClick={() => {
            column.setFilterValue(undefined);
            setSelection(undefined);
            setVisibility(false);
          }}
        >
          {i18n.buttonReset}
        </PButton>

        <PButton
          role="button"
          type="button"
          onClick={() => {
            column.setFilterValue(
              // Set undefined to remove the filter entirely
              (selection?.length ?? 0) > 0 ? selection : undefined,
            );
            setVisibility(false);
          }}
        >
          {i18n.buttonFilter}
        </PButton>
      </ActionGroup>
    </Popover>
  );
};
