import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  PButtonPure,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import { useDropzone } from 'react-dropzone';
import { styled } from '../stitches.config';

const byteSized = (bytes: number) => {
  return ['Bytes', 'KB', 'MB', 'GB', 'TB'].reduce(
    (acc: number | string, abbr: string) => {
      if (typeof acc === 'number') {
        return acc > 1000 ? acc / 1000 : `${Math.round(acc * 10) / 10} ${abbr}`;
      }

      return acc;
    },
    bytes,
  );
};

export const Dropzone = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$small',
  alignItems: 'center',
  padding: '$large',
  borderWidth: '2px',
  borderColor: '$contrastMedium',
  borderStyle: 'dashed',
  color: '$contrastHigh',
  outline: 'none',
  cursor: 'pointer',
});

const Files = styled('ul', {
  padding: '0',
  margin: '0',
  display: 'block',
});

const File = styled('li', {
  listStyleType: 'none',
  display: 'flex',
  position: 'relative',
  paddingTop: '$small',
  paddingBottom: '$small',
  borderBottom: '1px solid $contrastLow',
  gap: '$small',
});

const SortableFile = ({
  id,
  sortable,
  children,
  ...props
}: {
  id: string;
  sortable: boolean;
  children: React.ReactNode;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (!sortable) return <File {...props}>{children}</File>;

  return (
    <File ref={setNodeRef} {...props} style={style} {...attributes}>
      <PIcon
        name="menu-lines"
        ref={setActivatorNodeRef}
        {...listeners}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        data-testid="draggable-handle"
      />
      {children}
    </File>
  );
};

const FileName = styled('span', {
  display: 'inline-block',
  paddingLeft: '$xSmall',
  paddingRight: '$xSmall',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  flex: 1,
});

const ProgressBar = styled('div', {
  height: '6px',
  bottom: 0,
  left: 0,
  backgroundColor: '$success',
  borderRadius: '$medium',
  position: 'absolute',
  transition: 'width 0.5s 0s ease',
});

export const DropzoneInner = ({
  isDragActive,
  description,
  dragDescription,
}: {
  isDragActive: boolean;
  description?: string;
  dragDescription?: string;
}) => (
  <>
    {isDragActive && dragDescription ? (
      <PText>{dragDescription}</PText>
    ) : (
      description && <PText>{description}</PText>
    )}
    <PIcon name="upload" />
  </>
);

export const DropZoneFiles = ({
  files,
  existingFiles,
  sortableFiles,
  progress,
  onDelete,
  onDeleteExisting,
  onSortChange,
  showFileType = false,
}: {
  files?: {
    id: string;
    file: File;
  }[];
  existingFiles?: {
    id: string;
    name: string;
    size: number;
    type: string;
  }[];
  sortableFiles?: (
    | {
        id: string;
        file: File;
      }
    | {
        id: string;
        name: string;
        size: number;
        type: string;
      }
  )[];
  progress: number[];
  onDelete?: (index: number) => void;
  onDeleteExisting?: (id: string) => void;
  onSortChange?: (opt: { oldIndex: number; newIndex: number }) => void;
  showFileType?: boolean;
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8, // See: https://github.com/clauderic/dnd-kit/issues/591#issuecomment-1017050816
      },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={(event) => {
        if (!event.over || event.active.id === event.over.id) return;

        const oldIndex = sortableFiles!.findIndex(
          (i) => i.id === event.active.id,
        );
        const newIndex = sortableFiles!.findIndex(
          (i) => i.id === event.over!.id,
        );

        onSortChange?.({ oldIndex, newIndex });
      }}
    >
      <SortableContext
        items={sortableFiles ?? []}
        strategy={verticalListSortingStrategy}
      >
        <Files>
          {(sortableFiles ?? [...(files ?? []), ...(existingFiles ?? [])]).map(
            (file, index) =>
              // new files
              'file' in file ? (
                <SortableFile
                  key={`${file.file.name}_${index}`}
                  aria-label={file.file.name}
                  data-cy="upload"
                  sortable={!!sortableFiles}
                  id={file.id}
                >
                  {progress[index] > 0 && (
                    <ProgressBar style={{ width: `${progress[index]}%` }} />
                  )}
                  {onDelete && (
                    <PButtonPure
                      icon="delete"
                      size="small"
                      onClick={() => onDelete(index)}
                      role="button"
                      type="button"
                      aria-label="delete attachment"
                      hideLabel
                    />
                  )}
                  <FileName>{file.file.name}</FileName>
                  <span>{byteSized(file.file.size)}</span>
                  {showFileType && <TypeIcon fileType={file.file.type} />}
                </SortableFile>
              ) : (
                // already existing files
                <SortableFile
                  key={file.id}
                  aria-label={file.name}
                  sortable={!!sortableFiles}
                  id={file.id}
                >
                  {onDeleteExisting && (
                    <PButtonPure
                      icon="delete"
                      size="small"
                      onClick={() => onDeleteExisting(file.id)}
                      role="button"
                      type="button"
                      aria-label="delete attachment"
                      hideLabel
                    />
                  )}
                  <FileName>{file.name}</FileName>
                  <span>{byteSized(file.size)}</span>
                  {showFileType && <TypeIcon fileType={file.type} />}
                </SortableFile>
              ),
          )}
        </Files>
      </SortableContext>
    </DndContext>
  );
};

const TypeIcon = ({ fileType }: { fileType: string }) => {
  const type = fileType.split('/')[0];

  const mapTypeToIconName = (type: string) => {
    switch (type) {
      case 'image':
        return 'image';
      case 'video':
        return 'video';
      default:
        return 'document';
    }
  };

  return <PIcon name={mapTypeToIconName(type)} />;
};

export { useDropzone };
